@import 'src/assets/styles/common';

@mixin phone-shadow($x-offset: 0, $y-offset: 0, $opacity: 0.12) {
  box-shadow: $x-offset $y-offset 6px 0 rgba(0, 0, 0, $opacity);
}

.phone-frame-image {
  position: relative;

  padding: 17px 20px 19px 21px;

  width: 308px;
  height: 613px;

  z-index: 1;

  @include beyond-desktop {
    width: 340px;
  }
}

.phone-frame-image-el {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  pointer-events: none;

  z-index: 99;
}
